import React from 'react';
import { ICoreComponentInfo, OECoreComponent } from '../../components/OECoreComponent';

interface IComponentInfo extends ICoreComponentInfo {
    name: string;


}export const OECustomComponent: React.FunctionComponent<IComponentInfo> = ({ name, routeProperties, setRouteProperties }) => {
    switch (name.toLowerCase()) {
        default:
            return (<OECoreComponent setRouteProperties={setRouteProperties} name={name} routeProperties={routeProperties} />);
    }
};
